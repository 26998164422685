<template>
    <v-autocomplete
        @change="config.change && config.change($event)"
        v-model="model[config.key]"
        :items="config.items"
        :multiple="config.multiple"
        :label="config.label"
        :dense="config.dense"
        :item-value="config.itemValue"
        :item-text="config.itemText"
    ></v-autocomplete>
</template>

<script>
export default {
    name: "FormAutoComplete",
    props: ['config', 'model']
}
</script>

